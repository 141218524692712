import { NoData } from '@sixriver/react-support';

import { useWorkAreas } from '../../hooks/useWorkAreas';

interface WorkAreaCellProps {
	mapChunkId: string;
}

export function WorkAreaCell({ mapChunkId }: WorkAreaCellProps) {
	const { data: allWorkAreas } = useWorkAreas();

	const matchingMapChunk = allWorkAreas?.filter((workArea) => workArea?.mapChunkId === mapChunkId);

	if (matchingMapChunk.length > 0) {
		return <div>{matchingMapChunk[0].displayName}</div>;
	} else {
		return <NoData />;
	}
}
